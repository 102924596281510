import { useEffect } from 'react'

 export function MyHead() {

  useEffect(() => {
      import("amfe-flexible");
  }, []);
  return (
      <div>
          <head>
              <meta name="renderer" content="webkit" />
              <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no"></meta>
              <link rel="icon" type="image/x-icon" href="/favicon.ico" />
          </head>

      </div>)
}